/*Paradigm.css*/

.tab {
  position:absolute;
  left:150px; 
}

.word {
  background-image: linear-gradient(to right, rgba(255,255,255,0) 50%, #ddd 50%);
  background-position: -0% 0;
  background-size: 200% auto;
  color: #222;
  line-height: 1.15em;
  transition: background-position 0.5s ease-out;
}
.word:hover {
  background-position: -99.99% 0;
}