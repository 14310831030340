/*FrmRights.css*/

.btn-space {
    margin-right: 5px;
  }
  
  
  /* #data-rights-tree  */
  
  #data-rights-tree .padleft {
    padding-left: 14px;
  }
  
  #data-rights-tree .padleftwithplus {
    padding-left: 0px;
  }
  
  
  /* #data-rights-tree svg {
    -ms-transform: translateX(-0.4375em);
    -webkit-transform: translateX(-0.4375em);
    transform: translateX(-0.4375em);
  } */
  
  
  
  /*#data-rights-tree .padright {
    padding-right: 14px;
  }*/
  
  
  #data-rights-tree .form-check {
    display: inline;
  }
  
  #data-rights-tree ul.tree, ul.tree ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
    #data-rights-tree ul.tree ul {
      margin-left: 10px;
    }
  
    #data-rights-tree ul.tree li {
      margin: 0;
      padding: 0 7px;
      line-height: 20px;
      color: #369;
      /*font-weight: bold;*/
      border-left: 1px solid rgb(100,100,100);
    }
  
      #data-rights-tree ul.tree li:last-child {
        border-left: none;
      }
  
  /*#data-rights-tree ul.tree li:first-child {
    border-left: none;
  }*/
  
      #data-rights-tree ul.tree li:before {
        position: relative;
        top: -0.3em;
        height: 1em;
        width: 12px;
        color: white;
        border-bottom: 1px solid rgb(100,100,100);
        content: "";
        display: inline-block;
        left: -7px;
      }
  
      #data-rights-tree ul.tree li:last-child:before {
        border-left: 1px solid rgb(100,100,100);
      }
  
  